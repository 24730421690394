<template>
  <ag-grid-vue
    style="width: 100%; height: 400px;"
    class="ag-theme-alpine"
    :gridOptions="gridOptions"
    @grid-ready="onGridReady"
    :columnDefs="columnDefs"
    :rowData="rowData"
    :rowSelection="rowSelection"
    :setQuickFilter="updateSearchQuery"
    suppressDragLeaveHidesColumns
    @selection-changed="onSelectionChanged"
    suppressMovableColumns
    checkboxSelection="true"
  >
  </ag-grid-vue>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import moment from "moment";
import { mapGetters } from "vuex";

import { toShowAccountingPrice } from "../../../util/accountingPrice";

//  Table Components
import CancelButton from "./CellFramework/CancelButton.vue";

export default {
  emits: ["selected-assets"],
  data() {
    return {
      columnDefs: null,
      // rowSelection: "single",
      gridApi: null,
      gridColumnApi: null,
      gridOptions: {
        rowHeight: 50,
        defaultColDef: {
          resizable: true,
        },
      },
    };
  },
  props: {
    rowSelection: { required: false, default: "multiple" },
    page: { type: Number, required: true },
    perPage: { type: Number, required: true },
    assetData: { default: () => [] },
    searchText: {
      type: String,
    },
    otherColumn: [],
    menu: {
      type: String,
      default: "",
      required: true,
    },
  },
  beforeMount() {},
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    this.loopHeaderFreeColumn();
  },
  computed: {
    ...mapGetters({
      isRoleLower: "isRoleLower",
      permissionStatus: "permissionStatus",
      canUseDepreciation: "canUseDepreciation",
      canUseCustom2: "canUseCustom2",
      canUseCustom3: "canUseCustom3",
      canUseCustom4: "canUseCustom4",
      canUseCustom9: "canUseCustom9",
    }),

    hideCancelColumn() {
      const routeName = this.$route.name;
      if (routeName === "activate-asset" || routeName === "deactivate-asset") {
        return false;
      }
      return true;
    },

    rowData() {
      return this.assetData.map((asset, index) => {
        let requestUser = null;
        let requestUserName = null;
        const writeOff = asset.write_off;
        if (asset.pause_assets?.length > 0) {
          const pauseAssets = asset.pause_assets[0];
          requestUser =
            pauseAssets.request_activate_user ||
            pauseAssets.request_cancel_activate_user ||
            pauseAssets.request_deactivate_user ||
            pauseAssets.request_cancel_deactivate_user;
        } else {
          requestUser = writeOff?.request_write_off_user;
        }
        if (requestUser) {
          requestUserName = requestUser.name;
        }
        let dataTable = {
          index: (this.page - 1) * this.perPage + index + 1,
          id_asset: asset.id_asset,
          id_fixed_asset: asset.id_fixed_asset,
          name: asset.name ? asset.name : "-",
          description: asset.description ? asset.description : "-",
          note_acc: asset.note_acc ? asset.note_acc : "-",
          code_acc: asset.code_acc ? asset.code_acc : "-",
          name_acc: asset.name_acc ? asset.name_acc : "-",
          note_accounting_code: asset.note_accounting?.code || "-",
          note_accounting_name: asset.note_accounting?.name || "-",
          code_acc_dp: asset?.sub_note_accounting?.code_acc_dp || "-",
          name_acc_dp: asset?.sub_note_accounting?.name_acc_dp || "-",
          category: asset.categories ? asset.categories.name : "-",
          sub_category: asset.sub_category ? asset.sub_category.name : "-",
          department_code: asset.location_departments?.code || "-",

          location_building: asset.location_buildings
            ? asset.location_buildings.name
            : "-",
          location_floor: asset.location_floors
            ? asset.location_floors.name
            : "-",
          location_department: asset.location_departments
            ? asset.location_departments.name
            : "-",
          location_zone: asset.location_zones ? asset.location_zones.name : "-",
          note: asset.note ? asset.note : "-",
          id_qrcode: this.qrCode(asset),
          depreciation_status: this.dpStatusTranslate(
            asset.depreciation_status
          ),
          purchase_date: asset.purchase_date
            ? this.momentDay(asset.purchase_date)
            : "-",
          purchase_price: asset.purchase_price
            ? toShowAccountingPrice(asset.purchase_price)
            : "-",
          start_date: asset.start_date ? this.momentDay(asset.start_date) : "-",
          expire_date: asset.expire_date
            ? this.momentDay(asset.expire_date)
            : "-",
          useful_life: asset.useful_life ? asset.useful_life : "-",
          scrap_value: asset.scrap_value
            ? toShowAccountingPrice(asset.scrap_value)
            : "-",
          acc_depreciation: asset.acc_depreciation
            ? toShowAccountingPrice(asset.acc_depreciation)
            : "-",
          net_book: asset.net_book
            ? toShowAccountingPrice(asset.net_book)
            : "-",
          depreciation_date: asset.depreciation_date
            ? this.momentDay(asset.depreciation_date)
            : "-",
          prior_depreciation: asset.prior_depreciation
            ? toShowAccountingPrice(asset.prior_depreciation)
            : "-",
          prior_net_book: asset.prior_net_book
            ? toShowAccountingPrice(asset.prior_net_book)
            : "-",
          sum_depreciation_date: asset.sum_depreciation_date
            ? this.momentDay(asset.sum_depreciation_date)
            : "-",

          deactivate_dates:
            asset.pause_assets?.length > 0
              ? this.momentDay(asset.pause_assets[0]?.deactivate_at)
              : null,
          activate_dates:
            asset.pause_assets?.length > 0
              ? this.momentDay(asset.pause_assets[0]?.activate_at)
              : null,
          id_pause_asset:
            asset.pause_assets?.length > 0
              ? asset.pause_assets[0].id_pause_asset
              : null,
          id_write_off: writeOff?.id_write_off,
          write_off_date: writeOff?.write_off_date
            ? this.momentDay(writeOff.write_off_date)
            : "-",
          profit_loss: writeOff?.profit_loss
            ? toShowAccountingPrice(writeOff.profit_loss)
            : "-",
          write_off_amount: writeOff?.write_off_amount
            ? toShowAccountingPrice(writeOff.write_off_amount)
            : "-",
          residual_value: writeOff?.residual_value
            ? toShowAccountingPrice(writeOff.residual_value)
            : "-",
          asset_sector: asset.sector?.name || "-",
          asset_branch: asset.branch?.name || "-",
          insurance_start: asset.custom_start_time
            ? this.momentDay(asset.custom_start_time)
            : "-",
          insurance_end: asset.custom_end_time
            ? this.momentDay(asset.custom_end_time)
            : "-",
          vendor: asset.vendor?.name || "-",
          request_by: requestUserName || "-",
          writeoff_note: writeOff?.note_write_off || "-",
          // ------------------- START Custom 4 ModelAsset ------------------------
          model: asset.sub_category2?.name || "-",
          // ------------------- END Custom 4 ModelAsset ------------------------
        };
        const assetDetail = asset.asset_details;
        assetDetail.forEach((val) => {
          dataTable[`other_${val.column_no}`] = val.value || "-";
        });
        return dataTable;
      });
    },
  },
  components: {
    AgGridVue,
    CancelButton,
  },
  watch: {
    searchText() {
      this.updateSearchQuery(this.searchText);
    },
    rowData() {
      this.loopHeaderFreeColumn();
    },
  },
  methods: {
    moment,
    dpStatusTranslate(status) {
      switch (status) {
        case "active":
          return "Active";
        case "expire":
          return "Fully Depreciated";
        case "deactivate":
          return "Deactivate";
        case "write off":
          return "Written off / Disposed";
        default:
          return "-";
      }
    },
    qrCode(asset) {
      if (this.$route.params.summaryId) {
        return asset?.qrcode;
      } else {
        return asset?.qrcode?.id_qrcode
          ? asset?.qrcode?.id_qrcode
          : this.$t("home.not_reg");
      }
    },
    loopHeaderFreeColumn() {
      let columnDefs = [
        {
          headerName: this.$t("count.order"),
          field: "index",
          headerCheckboxSelection:
            this.rowSelection === "multiple" ? true : false,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true,
          maxWidth: 110,
          suppressSizeToFit: true,

          getQuickFilterText: (params) => {
            return "";
          },
        },
        {
          headerName: this.$t("home.fixedas_num"),
          field: "id_fixed_asset",
          sortable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: this.$t("home.as_name"),
          field: "name",
          sortable: true,
          minWidth: 200,
        },
        {
          headerName: this.$t("home.as_dt"),
          field: "description",
          sortable: true,
          minWidth: 200,
        },
        {
          headerName: this.$t("home.cat"),
          field: "category",
          sortable: true,
          minWidth: 200,
        },
        {
          headerName: this.$t("home.sub_cat"),
          field: "sub_category",
          sortable: true,
          minWidth: 200,
        },
        {
          headerName: this.$t("model.model"),
          field: "model",
          sortable: true,
          minWidth: 200,
          hide: !this.canUseCustom4,
        },
        {
          headerName: this.$t("asset.location"),
          field: "asset_sector",
          sortable: true,
          suppressSizeToFit: true,
          hide: !this.canUseCustom2 || this.isRoleLower,
        },
        {
          headerName: this.$t("asset.branch"),
          field: "asset_branch",
          sortable: true,
          suppressSizeToFit: true,
          hide: !this.canUseCustom2 || this.isRoleLower,
        },
        {
          headerName: this.$t("home.build"),
          field: "location_building",
          sortable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: this.$t("home.fl"),
          field: "location_floor",
          sortable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: this.$t("home.department_code"),
          field: "department_code",
          sortable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: this.$t("home.department"),
          field: "location_department",
          sortable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: this.$t("home.zone"),
          field: "location_zone",
          sortable: true,
          suppressSizeToFit: true,
        },
        {
          headerName: this.$t("home.note"),
          field: "note",
          sortable: true,
          minWidth: 150,
        },
        {
          headerName: "QR Code",
          field: "id_qrcode",
          sortable: true,
          suppressSizeToFit: true,
          cellClass: "text-center",
          cellStyle: (params) => {
            if (params.value === this.$t("home.not_reg")) {
              return { color: "#888888" };
            }
            return null;
          },
        },
        {
          headerName: this.$t("depreciation.acc_status"),
          field: "depreciation_status",
          sortable: true,
          minWidth: 150,
          cellClass: "text-center",
          cellRenderer: (params) => {
            return params.value;
          },
          cellStyle: (params) => {
            switch (params.value) {
              case "Active":
                return { color: "#00AB1B" };
              case "Expired":
                return { color: "#7B61FF" };
              case "Deactivate":
                return { color: "#E9A800" };
              case "Write off (Sold)":
                return { color: "#ED1616" };
              default:
                return null;
            }
          },
        },
        {
          headerName: this.$t("asset.asset_acc_code"),
          field: "note_accounting_code",
          sortable: true,
          minWidth: 150,
          suppressSizeToFit: true,
          hide: !this.canUseDepreciation,
          cellClass: "text-center",
        },
        {
          headerName: this.$t("asset.asset_acc_name"),
          field: "note_accounting_name",
          sortable: true,
          minWidth: 150,
          suppressSizeToFit: true,
          hide: !this.canUseDepreciation,
          cellClass: "text-center",
        },
        {
          headerName: this.$t("asset.acc_code"),
          field: "code_acc",
          sortable: true,
          minWidth: 150,
          suppressSizeToFit: true,
          hide: !this.canUseDepreciation,
          cellClass: "text-center",
        },
        {
          headerName: this.$t("asset.dep_acc_name"),
          field: "name_acc",
          sortable: true,
          minWidth: 150,
          suppressSizeToFit: true,
          hide: !this.canUseDepreciation,
          cellClass: "text-center",
        },
        {
          headerName: this.$t("asset.accumulate_dep_acc_code"),
          field: "code_acc_dp",
          sortable: true,
          minWidth: 150,
          suppressSizeToFit: true,
          hide: !this.canUseDepreciation,
          cellClass: "text-center",
        },
        {
          headerName: this.$t("asset.accumulate_dep_acc_name"),
          field: "name_acc_dp",
          sortable: true,
          minWidth: 150,
          suppressSizeToFit: true,
          hide: !this.canUseDepreciation,
          cellClass: "text-center",
        },
      ];

      if (!this.isRoleLower) {
        const customColumnJGT = [
          {
            position: 35,
            value: {
              headerName: this.$t("asset.insurance_start"),
              field: "insurance_start",
              sortable: true,
              suppressSizeToFit: true,
              cellClass: "text-center",
            },
          },
          {
            position: 36,
            value: {
              headerName: this.$t("asset.insurance_end"),
              field: "insurance_end",
              sortable: true,
              suppressSizeToFit: true,
              cellClass: "text-center",
            },
          },
          {
            position: 37,
            value: {
              headerName: this.$t("asset.vendor"),
              field: "vendor",
              sortable: true,
              suppressSizeToFit: true,
            },
          },
        ];
        const fix_column = [
          {
            headerName: this.$t("asset.purchase_date"),
            field: "purchase_date",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
            hide: !this.canUseDepreciation,
            cellClass: "text-center",
          },
          {
            headerName: this.$t("asset.start_date"),
            field: "start_date",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
            hide: !this.canUseDepreciation,
            cellClass: "text-center",
          },
          {
            headerName: this.$t("asset.exp_date"),
            field: "expire_date",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
            hide: !this.canUseDepreciation,
            cellClass: "text-center",
          },
          {
            headerName: this.$t("asset.purchase_price"),
            field: "purchase_price",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
            hide: !this.canUseDepreciation,
            cellClass: "text-end",
          },
          {
            headerName: this.$t("asset.useful_life"),
            field: "useful_life",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
            hide: !this.canUseDepreciation,
            cellClass: "text-end",
          },
          {
            headerName: this.$t("asset.scrap_value"),
            field: "scrap_value",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
            hide: !this.canUseDepreciation,
            cellClass: "text-end",
          },
          {
            headerName: this.$t("asset.dep_at_date"),
            field: "acc_depreciation",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
            hide: !this.canUseDepreciation || this.$route.name === "sold-asset",
            cellClass: "text-end",
          },
          {
            headerName: this.$t("asset.nb_at_date"),
            field: "net_book",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
            hide: !this.canUseDepreciation || this.$route.name === "sold-asset",
            cellClass: "text-end",
          },
          {
            headerName: this.$t("asset.dep_date"),
            field: "depreciation_date",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
            hide: !this.canUseDepreciation,
            cellClass: "text-center",
          },
          {
            headerName: this.$t("asset.prior_dep"),
            field: "prior_depreciation",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
            hide: !this.canUseDepreciation,
            cellClass: "text-end",
          },
          {
            headerName: this.$t("asset.prior_nb"),
            field: "prior_net_book",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
            hide: !this.canUseDepreciation,
            cellClass: "text-end",
          },
          {
            headerName: this.$t("asset.sum_depreciation_date"),
            field: "sum_depreciation_date",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
            hide: !this.canUseDepreciation || !this.canUseCustom9,
            cellClass: "text-end",
          },
          {
            headerName: this.$t("asset.pause_date"),
            field: "deactivate_dates",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
            hide: !this.canUseDepreciation,
            cellRenderer: (params) => {
              return params.value || "-";
            },
            cellClass: "text-center",
          },
          {
            headerName: this.$t("asset.active_date"),
            field: "activate_dates",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
            hide: !this.canUseDepreciation,
            cellRenderer: (params) => {
              return params.value || "-";
            },
            cellClass: "text-center",
          },
          {
            headerName: this.$t("asset.writeoff_date"),
            field: "write_off_date",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
            hide: !this.canUseDepreciation,
            cellClass: "text-center",
          },
          {
            headerName: this.$t("asset.writeoff_price"),
            field: "write_off_amount",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
            hide: !this.canUseDepreciation,
            cellClass: "text-end",
          },
          {
            headerName: this.$t("asset.net_writeoff_price"),
            field: "residual_value",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
            hide: !this.canUseDepreciation,
            cellClass: "text-end",
          },
          {
            headerName: this.$t("asset.profit_loss"),
            field: "profit_loss",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
            hide: !this.canUseDepreciation,
            cellClass: "text-end",
          },
          {
            headerName: this.$t("home.intendant"),
            minWidth: 170,
            field: "intendant",
            sortable: true,
            hide: !this.canUseCustom3,
          },
          {
            headerName: this.$t("home.as_stat"),
            suppressSizeToFit: true,
            field: "counting_status",
            sortable: true,
            hide: !this.canUseCustom3,
            cellStyle: (params) => {
              if (
                params.value === this.$t("count.repar") ||
                params.value === this.$t("count.irrepar") ||
                params.value === this.$t("count.wait_as")
              ) {
                return { color: "#C18B00" };
              } else if (params.value === this.$t("count.not_reg")) {
                return { color: "#888888" };
              } else if (
                params.value === this.$t("count.lost") ||
                params.value === this.$t("filter.rep_lost")
              ) {
                return { color: "#FF0000" };
              } else if (params.value === this.$t("count.inuse")) {
                return { color: "#1E9400" };
              } else if (params.value === this.$t("home.regist")) {
                return { color: "#00AB1B" };
              }
              return null;
            },
            cellClass: "text-center",
          },
          {
            headerName: this.$t("asset.insurance_start"),
            field: "insurance_start",
            sortable: true,
            suppressSizeToFit: true,
            hide: !this.canUseCustom2,
          },
          {
            headerName: this.$t("asset.insurance_end"),
            field: "insurance_end",
            sortable: true,
            suppressSizeToFit: true,
            hide: !this.canUseCustom2,
          },
          {
            headerName: this.$t("asset.vendor"),
            field: "vendor",
            sortable: true,
            suppressSizeToFit: true,
            hide: !this.canUseCustom2,
          },
        ];
        const verify_column = [
          {
            headerName: this.$t("depreciation.request_by"),
            field: "request_by",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
          },
          {
            headerName: this.$t("depreciation.writeoff_note"),
            field: "writeoff_note",
            sortable: true,
            minWidth: 150,
            suppressSizeToFit: true,
            hide: this.menu !== "CONF_WRITEOFF",
          },
        ];
        columnDefs = columnDefs.concat(fix_column);
        if (this.permissionStatus.company.custom_id === 2) {
          for (const item of customColumnJGT) {
            columnDefs.splice(item.position, 0, item.value);
          }
        }
        let column = this.otherColumn;
        for (const col of column) {
          let header = {
            headerName: col.name,
            field: `other_${col.column_no}`,
            cellRenderer: (params) => {
              return params.value || "-";
            },
            suppressSizeToFit: true,
          };
          columnDefs.push(header);
        }
        columnDefs = columnDefs.concat(verify_column);
      }
      this.columnDefs = columnDefs;
    },
    onGridReady() {
      this.gridApi = this.gridOptions.api;
      this.gridColumnApi = this.gridOptions.columnApi;
      // this.gridColumnApi.autoSizeAllColumns();
    },
    momentDay(date) {
      return moment(date).isValid() ? moment(date).format("DD/MM/YYYY") : null;
    },
    getSelectedRowData() {
      let selectedNodes = this.gridApi.getSelectedNodes();
      let selectedData = selectedNodes.map((node) =>
        this.assetData.find(
          (asset) => asset.id_fixed_asset === node.data.id_fixed_asset
        )
      );
      return selectedData;
    },

    onSelectionChanged(event) {
      const selectedNodes = event.api.getSelectedNodes();
      const selectedData = selectedNodes.map((node) => {
        return node.data;
      });
      this.$emit("selected-assets", selectedData);
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
      this.gridApi.refreshCells();
    },
  },
};
</script>

<style scoped>
* >>> .ag-header-cell-label {
  justify-content: center;
}
* >>> .ag-header-row-column {
  background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%);
  color: white;
}
</style>
